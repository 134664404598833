import React from 'react';
import Content from '../views/Fleet/Pricing';

export default ({location}) => <Content location={location} seo={{
  title: 'Se en pris på vores flådestyringstjeneste',
  lang: 'da-DK',
  description: 'Hvad koster flådestyring? Tjek priserne på vores hjemmeside. Få adgang til OBI+ Fleet med vores gratis administrationsværktøj og opgrader når som helst.',
  meta: [{
    name: 'keywords',
    content: 'Pris'
  }]
}}/>