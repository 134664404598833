import React from 'react';
import Layout from "../../../components/Layout"
import SEO from "../../../components/seo";
import Subheader from '../../../components/Header/navmap/fleet';
import sitemap from '../../../data/sitemap';
import Pricing from './Pricing';
import FAQ from './FAQ';

export default ({ seo, location }) => (
  <Layout overlay 
    page={sitemap.industries}
    subpage={sitemap.fleet.priceTable}
    subheader={Subheader}
    url='_url:fleet-management-pricing-tables'>
    <SEO {...seo} location={location}/>
    <Pricing/>
    <FAQ/>
  </Layout>
);